.PhoneInputInput{
    width: 401px;
    height: 32px;
}
.PhoneInputCountry{
    font-size: 27px;
    height: 43px;
}
.special-label{
    display: none !important;
}
.form-control {
    width:427px !important;
    height:42px !important;
}
@media only screen and (max-width: 600px) {
    .form-control {
        width:220px !important;
        height:42px !important;
    }
  }